import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import { TABLE_NAMES, PRE_CONDITIONS_NAMES, PRE_CONDITIONS_RULES } from "@/constants"
import TransitionAddRule from "@/components/transition/add-rule"

export default {
  name: "TransitionPreConditions",
  data() {
    return {
      showAddRuleDialog   : false,
      showDeleteRuleDialog: false,
      ruleToDelete        : {}
    }
  },
  components: {
    TransitionAddRule
  },
  props: {
    pTransitionPreConditions         : Array,
    pPreConditions                   : Array,
    pInitialTransition               : Boolean,
    pIsTransitionToStatusDoneCategory: Boolean,
    pSystemDefault                   : Boolean
  },
  computed: {
    headersForPreConditionsTable() {
      return getHeadersForTable(TABLE_NAMES.TRANSITION_PRE_CONDITIONS, this.$t.bind(this))
    },
    footersForPreConditionsTable() {
      return getFootersForTable(TABLE_NAMES.TRANSITION_PRE_CONDITIONS, this.$t.bind(this))
    },
    itemsForPreConditionsTable() {
      return this.pTransitionPreConditions.map(preCondition => {
        let ruleName               = ""
        const preConditionKeys     = Object.keys(preCondition.value)[0].split(".")
        const preConditionValue    = Object.values(preCondition.value)[0]
        const preConditionConstant = this.getPreCondition(preConditionKeys, preCondition.type)
        if (preConditionConstant.value === preConditionValue) {
          ruleName = preConditionConstant.label
        }

        return {
          id            : preCondition.id,
          ruleName      : this.$t(ruleName),
          type          : preCondition.type,
          value         : preCondition.value,
          disabledButton: this.pIsTransitionToStatusDoneCategory && ruleName === "1148"
        }
      })
    },
    rules() {
      return PRE_CONDITIONS_RULES.map(rule => {
        if (rule.items.length) {
          return {
            ...rule,
            items: rule.items.map(item => ({
              text : this.$t(item.text),
              value: item.value
            }))
          }
        }
      })
    }
  },
  methods: {
    getPreCondition(preConditionKeys, type) {
      return preConditionKeys.reduce((acc, key) => {
        return acc[key]
      }, PRE_CONDITIONS_NAMES[type])
    },
    addRule(rule) {
      let ruleToBeAdded
      this.showAddRuleDialog = false

      const key           = rule.find(eachRule => eachRule.key === "verify").value
      const checkOperator = rule.find(eachRule => eachRule.key === "checkOperator").value
      const checkValue    = rule.find(eachRule => eachRule.key === "checkValue").value

      if (key === "report.status" && checkOperator === "equals" && checkValue === "closed") {
        ruleToBeAdded = {
          [key]: checkValue
        }
      }

      const isRuleExistsInDatabase = this.pPreConditions.find(preCondition => {
        return JSON.stringify(preCondition.value) === JSON.stringify(ruleToBeAdded) && preCondition.type === "check field"
      })

      if (isRuleExistsInDatabase) {
        const isRuleAlreadyExistsInTransition = this.pTransitionPreConditions.some(preCondition => {
          return JSON.stringify(preCondition.value) === JSON.stringify(ruleToBeAdded) && preCondition.type === "check field"
        })

        if (!isRuleAlreadyExistsInTransition) {
          const preConditionRules = [
            ...this.pTransitionPreConditions,
            {
              id           : isRuleExistsInDatabase.id,
              type         : "check field",
              value        : ruleToBeAdded,
              systemDefault: isRuleExistsInDatabase.systemDefault
            }
          ]
          this.$emit("updateRules", preConditionRules)
        }
      }
    },
    showDialogToConfirmDeleteRule(rule) {
      this.showDeleteRuleDialog = true
      this.ruleToDelete         = rule
    },
    handleCancelDeleteRule() {
      this.showDeleteRuleDialog = false
      this.ruleToDelete         = {}
    },
    handleDeleteRuleConfirmed() {
      this.showDeleteRuleDialog = false
      const preConditionRules   = this.pTransitionPreConditions.filter(preCondition =>
        !(preCondition.type === this.ruleToDelete.type &&
        JSON.stringify(preCondition.value) === JSON.stringify(this.ruleToDelete.value))
      )
      this.$emit("updateRules", preConditionRules)
      this.ruleToDelete = {}
    }
  }
}